.previewImgBox {
  height: 38px;
  position: relative;
}
.previewImgBox img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 4px;
}
.previewImgBox svg {
  font-size: 16px;
  position: absolute;
  right: 2px;
  top: 2px;
  cursor: pointer;
}

.Mui-selected {
  background: #7258db !important;
  color: #ffffff !important;
  border-radius: 10px !important;
}

.css-1aquho2-MuiTabs-indicator {
  display: none !important;
}
span.MuiTabs-indicator.css-ttwr4n {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
/* input[type="number"] {
  -moz-appearance: ;
} */

.modalss {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  margin: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.modal-contentss {
  position: relative;
  background: white;
  padding: 6px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: scale(0.9);
  transition: transform 0.3s ease;
}

.modal-contentss img {
  max-width: 640px;
  max-height: 450px;
}

.modalss.show {
  opacity: 1;
  visibility: visible;
}

.modal-contentss.show {
  transform: scale(1);
}

.close-btnss {
  position: absolute;
  top: -10px;
  right: -10px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

.modal-overlayss {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  margin: 0;
}

.modal-overlayss.show {
  opacity: 1;
  visibility: visible;
} 

/* <<<<<chatsoppot>>>>>>>>> */
.chatSupport {
  background-color: #ffffff;
  border-radius: 20px;
  overflow: hidden;
  height: 100vh;
  margin: 10px 0px 0px;
  position: relative;
}
.ticket {
  background-color: #7258db;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ticket h4 {
  color: #fff;
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
}
.ticket button {
  font-size: 14px;
  background-color: #fff;
  color: #7258db;
  padding: 6px 10px;
  border-radius: 100px;
  border: 0px;
  font-weight: 600;
}
.ticketBody {
  padding: 20px;
  height: calc(100vh - 140px);
  overflow: auto;
}
.userChatLeft {
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 8px 8px 8px 0px;
  max-width: fit-content;
  margin: 5px 0px;
  width: 85%;
}
.userChatRight {
  text-align: left;
  border-radius: 8px 8px 0px 8px;
  background-color: #ece7ff;
  padding: 8px;
  max-width: fit-content;
  margin: 5px 0px;
  width: 85%;
}
.ChatRightOuter {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: end;
}
.chatButtomfix {
  position: absolute;
  bottom: 0;
  background-color: #f5f5f5;
  padding: 15px;
  border-top: 1px solid #ece7ff;
  width: 100%;
  display: flex;
  column-gap: 5px;
}
.chatButtomfix button {
  background-color: #7258db;
  padding: 10px 12px;
  border-radius: 10px;
  border: 0px;
}
.chatButtomfix input {
  padding: 10px;
  border-radius: 10px;
  font-size: 16px;
}

/* .ticketBody {
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
} */

.userChatLeft {
  text-align: left;
}

.messageCard {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  margin: 5px;
}

.textMessage {
  background-color: #e1ffc7;
  padding: 10px;
  border-radius: 8px;
  display: inline-block;
  margin: 5px;
}

.timestamp {
  display: block;
  font-size: 12px;
  color: gray;
  margin-top: 5px;
}

.chatBottomFix {
  display: flex;
  margin-top: 10px;
}

.chatBottomFix input {
  flex: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.chatBottomFix button {
  padding: 8px 20px;
  margin-left: 5px;
  border-radius: 5px;
  background-color: #4caf50;
  color: white;
  border: none;
  cursor: pointer;
}
.userChatRight .messageTime, .userChatLeft .messageTime {
  font-size: 10px;
  display: block;
  margin: 5px 0px 0px;
  text-align: right;
}
.userChatLeft .firstMessageUser span {
  display: block;
  margin: 3px 0px;
}
